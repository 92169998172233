<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">알림톡 템플릿 대량 등록</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">알림톡 템플릿 대량 등록</h3>
          </template>
          <div>
            <div class="form-group row">
              <div class="col-md-2">
                <label class="col-form-label form-control-label text-center">신규 템플릿 업로드</label>
                <base-button type="info" block @click="download('new_template_upload_v1.7.xlsx')" class="mt-2 btn-sm">신규 등록 양식 받기</base-button>
                <base-button type="warning" block @click="downloadFailExcelList(newFailExcelList)" class="mt-2 btn-sm" v-if="newFailExcelList.length > 0">실패 사유 받기</base-button>
              </div>
              <div class="col-md-10">
                <el-upload
                  class="upload-wrapper-new"
                  action="#"
                  drag
                  :limit="1"
                  :accept="acceptedFiles"
                  :on-change="onChangeNew"
                  :auto-upload="false"
                  :file-list="form.newRegisterFile">

                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                  <div class="el-upload__tip text-danger" slot="tip">용량 3MB 제한</div>
                </el-upload>
                <base-button type="primary" block @click="processNewTemplateFileUpload" class="mt-2">신규 템플릿 업로드</base-button>
              </div>
            </div>
            <div class="form-group row mb-0">
              <div class="col-md-2">
                <label class="col-form-label form-control-label text-center">기존 템플릿 업로드</label>
                <base-button type="info" block @click="download('registered_template_upload_v1.1.xlsx')" class="mt-2 btn-sm">기존 등록 양식 받기</base-button>
                <base-button type="warning" block @click="downloadFailExcelList(legacyFailExcelList)" class="mt-2 btn-sm" v-if="legacyFailExcelList.length > 0">실패 사유 받기</base-button>
              </div>
              <div class="col-md-10">
                <el-upload
                  class="upload-wrapper-legacy"
                  action="#"
                  drag
                  :limit="1"
                  :accept="acceptedFiles"
                  :on-change="onChangeLegacy"
                  :auto-upload="false"
                  :file-list="form.legacyRegisteredFile">

                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                  <div class="el-upload__tip text-danger" slot="tip">용량 3MB 제한</div>
                </el-upload>
                <base-button type="primary" block @click="processLegacyTemplateFileUpload" class="mt-2">기존 템플릿 업로드</base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
import commJsUtilMixin from '@/shared/mixins/commJsUtil';
import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
import FileInput from '@/shared/components/Inputs/FileInput'
import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { Upload, Button } from 'element-ui';
const XLSX = require('xlsx');

export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  mixins: [
    commPaginationMixin, commJsUtilMixin, commValidatorMixin
  ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload,
    [Button.name]: Button,
    FileInput
  },
  data() {
    return {
      form: {
        newRegisterFile: [],
        legacyRegisteredFile: [],
      },
      newFailExcelList : [],
      legacyFailExcelList : [],
      acceptedFiles: '.xlsx',
    };
  },
  created() {

  },
  methods: {
    newFilesChange(files) {
      this.form.newRegisterFile = files
    },
    legacyFilesChange(files) {
      this.form.legacyRegisteredFile = files
    },
    /**
     * 신규 템플릿 업로드 기능
     */
    processNewTemplateFileUpload() {
      if(this.form.newRegisterFile.length > 0) {
        let formData = new FormData();

        // 등록 파일 세팅
        for(let i=0, length = this.form.newRegisterFile.length; i < length; i++) {
          if(this.form.newRegisterFile[i].status === 'ready') {
            formData.append("file", this.form.newRegisterFile[i].raw);
          }
        }

        this.newFailExcelList = [];   //실패사유 초기화
        this.$axios.post(`${USER_API_PREFIX}/template/upload/new`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => {
          if (res.data.result.code === RESULT_CODE.SUCCESS) {
            let jobCnt = res.data.result.jobCnt;
            let failCnt = res.data.result.failCnt;
            let failureTemplateList = res.data.result.failureTemplateList;
            let failTemplateList = res.data.result.failTemplateList;
            if(failTemplateList != null ){
              failTemplateList.forEach((item , index) => {
                this.newFailExcelList.push({
                  'NO' : index+1,
                  '템플릿' : item.kkoTemplateCd,
                  '실패사유' : item.description
                });
              });
            }

            swal({
              title: `[신규 템플릿 등록작업 진행결과]`,
              text: `템플릿수( ${jobCnt} ), 성공건수( ${jobCnt - failCnt} ), 실패건수( ${failCnt} )\n\n실패한 템플릿: ${failureTemplateList}`,
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-success',
              type: 'success'
            });
            this.form.newRegisterFile = [];
          } else {
            this.notify('warning', res.data.result.message);
          }
        });
      }else{
        swal({
          title: '경고',
          text: '업로드 파일이 없습니다.',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning',
          type: 'warning',
          width: 400
        });
      }
    },
    /**
     * 기존 카카오에 등록된 템플릿 업로드 기능
     */
    processLegacyTemplateFileUpload() {
      if(this.form.legacyRegisteredFile.length > 0) {
        let formData = new FormData();

        // 등록 파일 세팅
        for(let i=0, length = this.form.legacyRegisteredFile.length; i < length; i++) {
          if(this.form.legacyRegisteredFile[i].status === 'ready') {
            formData.append("file", this.form.legacyRegisteredFile[i].raw);
          }
        }
        this.legacyFailExcelList = [];         //실패사유 초기화

        this.$axios.post(`${USER_API_PREFIX}/template/upload/legacy`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => {
          if (res.data.result.code === RESULT_CODE.SUCCESS) {
            let jobCnt = res.data.result.jobCnt;
            let failCnt = res.data.result.failCnt;
            let failureTemplateList = res.data.result.failureTemplateList;
            let failTemplateList = res.data.result.failTemplateList;
            if(failTemplateList != null ){
              failTemplateList.forEach((item , index) => {
                this.legacyFailExcelList.push({
                  'NO' : index+1,
                  '템플릿' : item.kkoTemplateCd,
                  '실패사유' : item.description
                });
              });
            }

            swal({
              title: `[기존 템플릿 등록작업 진행결과]`,
              text: `템플릿수( ${jobCnt} ), 성공건수( ${jobCnt - failCnt} ), 실패건수( ${failCnt} )\n\n실패한 템플릿: ${failureTemplateList}`,
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-success',
              type: 'success'
            });
            this.form.legacyRegisteredFile = [];
          } else {
            this.notify('warning', res.data.result.message);
          }
        });
      }else{
        swal({
          title: '경고',
          text: '업로드 파일이 없습니다.',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning',
          type: 'warning',
          width: 400
        });
      }
    },
    /**
     * list to excel
     */
    downloadFailExcelList(list) {
      const wb = XLSX.utils.book_new()             // 엑셀 파일 생성 (workbook)
      const ws = XLSX.utils.json_to_sheet(list)    // 시트 생성 (worksheet) 및 데이터 삽입

      XLSX.utils.book_append_sheet(wb, ws, 'sheet1')  // 엑셀 파일에 시트 추가

      XLSX.writeFile(wb, 'result.xlsx') // 엑셀 다운로드
    },

    /**
     * 엑셀 파일 (양식) 다운로드
     */
    download(filename) {
      this.$axios.get(`${USER_API_PREFIX}/template/download`, {
        params: {
          filename: filename
        },
        responseType: 'blob'
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        link.click();
        window.URL.revokeObjectURL(url);
      });
    },
    /**
     * 파일 등록 이벤트 핸들러
     * @param file
     * @param fileList
     */
    onChangeNew(file, fileList) {
      if(file.status === 'ready') {
        const limitMb = 3;
        const maxSizeLimit = limitMb * 1024 * 1024;
        if(file.size > maxSizeLimit) {
          this.notify('warning', `(${file.name}) 첨부파일은 ${limitMb}MB를 초과할 수 없습니다.`);
          fileList.splice((fileList.length-1), 1)
        }
      }
      this.form.newRegisterFile = fileList;
    },
    /**
     * 파일 등록 이벤트 핸들러
     * @param file
     * @param fileList
     */
    onChangeLegacy(file, fileList) {
      if(file.status === 'ready') {
        const limitMb = 3;
        const maxSizeLimit = limitMb * 1024 * 1024;
        if(file.size > maxSizeLimit) {
          this.notify('warning', `(${file.name}) 첨부파일은 ${limitMb}MB를 초과할 수 없습니다.`);
          fileList.splice((fileList.length-1), 1)
        }
      }
      this.form.legacyRegisteredFile = fileList;
    },
    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      });
    }
  }
};
</script>
<style scoped>
  .no-border-card .card-footer{
    border-top: 0;
  }
  .f-s-3 {
    font-size: 0.75rem;
  }
  .f-s-4 {
    font-size: 1rem;
  }
</style>
