<template>
  <modal modalClasses="base-template-register-modal" size="lg" body-classes="pt-0 pb-0" footerClasses="pt-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">기본 템플릿 등록</h6>

    <template>
      <div class="container-fluid">
        <card body-classes="pb-1" footer-classes="pb-2">
          <div class="form-row mb-1">
            <div class="col-md-1">
              <base-input>
                <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-9">
            </div>
            <div class="col-md-2">
              <div class="float-xl-right">
              </div>
            </div>
          </div>
          <div class="form-row mb-1 card-columns-max-height">
            <div v-if="templateList && templateList.length > 0" class="card-columns  bg-secondary p-2">
              <card class="text-center border-card-kakao" headerClasses="p-2" v-for="(option, index) in templateList">
                <template slot="header">
                  <div class="form-row mb-1">
                    <div class="col-md-6 text-left">
                      <base-button type="primary" size="sm" class="no-click" outline>{{sendTypes[option.sendType]}}</base-button>
                    </div>
                    <div class="col-md-6">
                      <div class="float-xl-right">
                        <el-tooltip content="복사" placement="top">
                          <base-button type="primary" size="sm" class="btn-icon-only" @click="copy(option, index)">
                            <i class="ni ni-single-copy-04"></i>
                          </base-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
                <h5 class="card-title text-left">{{option.templateCd}}</h5>
                <p class="card-text text-left" style="white-space: pre-wrap;" >{{option.templateContent}}</p>
                <p class="card-text text-left"><small class="text-muted">{{option.useYn == 'Y' ? '사용' : '미사용'}}</small></p>
              </card>
            </div>
            <div v-else class="el-table__empty-block" style="min-height: 350px; width: 100%;">
              <span class="el-table__empty-text">등록된 템플릿이 없습니다.</span>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="getList">
            </base-pagination>
          </div>
        </card>
      </div>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <!--<base-button type="primary" class="ml-auto" @click="processRegister">등록</base-button>-->
    </template>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option} from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX } from '@/shared/util/const';

  export default {
    name: 'BaseTemplateRegisterLayer',
    inject: ['$validator'],
    mixins: [
      commJsUtilMixin, commPaginationMixin, commValidatorMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    props : {
    },
    data() {
      return {
        sendTypes: {"SM": "SMS", "LM": "LMS", "MM": "MMS"},
        templateList: [],
      }
    },
    created() {
      this.getList(this.pagination.currentPage);
    },
    methods: {

      /**
       * 목록 조회
       */
      getList(page) {
        const pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/baseTemplate/page`, {
          params: {
            page: pageNo,
            limit: this.pagination.perPage
          }
        }).then(res => {
          this.templateList = res.data.data; // 목록정보 세팅
          this.pagination.currentPage = pageNo; // 페이지 번호 세팅
          this.pagination.total = res.data.totalPage; // 전체페이지수 세팅
        });
      },

      copy(template, index){
        this.$axios.post(`${USER_API_PREFIX}/smsTemplate/insert`, template).then(res => {
          this.$emit('register');
          this.notify('default', '복사 되었습니다.');
        });
      },

      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },

      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    }
  };
</script>

<style>
  .card-columns-max-height{
    max-height: 500px;
    overflow-y: auto;
  }
  .base-template-register-modal {
    max-width: 1200px;
    min-height: 800px;
  }
</style>
