<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">템플릿 등록</h6>

    <template>
      <form role="form" class="needs-validation">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">템플릿 코드 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="template.templateCd" name="템플릿 코드" :error="_getError('템플릿 코드')" :maxlength="30" v-validate="'required'" placeholder="템플릿 코드 입력 (최대 30자)"/>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">템플릿 명 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="template.description" name="템플릿 명" :maxlength="200" :error="_getError('템플릿 명')" v-validate="'required'" placeholder="템플릿 명 입력 (최대 200자)"/>
          </div>
        </div>
<!--        <div class="form-group row mb-0">-->
<!--          <label class="col-md-3 col-form-label form-control-label">사용여부 <span class="text-danger">*</span></label>-->
<!--          <div class="col-md-9 pt-1">-->
<!--            <base-radio name="Y" :inline="true" class="mb-1" v-model="template.useYn">사용</base-radio>-->
<!--            <base-radio name="N" :inline="true" class="mb-1" v-model="template.useYn">미사용</base-radio>-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group row mb-3">
          <label class="col-md-3 col-form-label form-control-label">템플릿 타입 <span class="text-danger">*</span></label>
          <div class="col-md-9 pt-2">
            <button-radio-group :options="sendTypes" button-classes="btn-sm" v-model="template.sendType" @change="changeSendType"/>
          </div>
        </div>
        <div v-if="['LM'].includes(template.sendType)" class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">컨탠츠 제목 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="template.contentTitle" name="name" :error="_getError('name')" :maxlength="20" v-validate="'required'" placeholder="제목 입력"></base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">템플릿 내용 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input additional-classes="mb-0">
              <textarea class="form-control" id="message" rows="8" placeholder="내용 입력" v-model="template.templateContent" :maxlength="smsMaxLength"/>
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <div class="col-md-12">
            <div class="float-xl-right">
              {{smsLength}} / {{smsMaxLength}} byte
            </div>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button v-if="modify" type="primary" class="ml-auto" @click="processUpdate">수정</base-button>
      <base-button v-else type="primary" class="ml-auto" @click="processRegister">등록</base-button>
    </template>

  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX } from '@/shared/util/const';

  export default {
    name: 'SmsTemplateRegisterLayer',
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    props : {
      modify: {
        type : Boolean,
        default: false
      },
      oldTemplate: {
        type : Object,
        default: () => ({})
      },
    },
    data() {
      return {
        sendTypes: [ {label : 'SMS', value : 'SM'}, {label : 'LMS', value : 'LM'}],
        template: {}
      }
    },
    created() {
      this.template = this.modify ? {...this.oldTemplate} : {templateCd : '', sendType : 'SM', description : '', contentTitle : '', templateContent : '', useYn : 'Y'}
    },
    methods: {
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 템플릿 등록 실행
       */
      register() {
        this._swalQuestion({ title:'등록 하시겠습니까?' }, () => {
          this.$axios.post(`${USER_API_PREFIX}/smsTemplate/insert`, this.template).then(res => {
            this.$emit('register');
          });
        });
      },
      /**
       * 등록 실행 전, validate check!
       */
      processRegister() {
        this._validateBeforeSubmit(this.register);
      },
      /**
       * 템플릿 등록 실행
       */
      update() {
        this._swalQuestion({ title:'수정 하시겠습니까?' }, () => {
          this.$axios.patch(`${USER_API_PREFIX}/smsTemplate/update`, this.template).then(res => {
            this.$emit('register');
          });
        });
      },
      /**
       * 등록 실행 전, validate check!
       */
      processUpdate() {
        this._validateBeforeSubmit(this.update);
      },
      /**
       * 발송타입 체크
       */
      changeSendType() {
        if(this.smsLength >= this.smsMaxLength) this.template.templateContent = this._maxLengthTextEucKr(this.template.templateContent, this.smsMaxLength);
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch: {
      'template.templateContent' : function(val, old){
        if(this.smsLength >= this.smsMaxLength) this.template.templateContent = this._maxLengthTextEucKr(this.template.templateContent, this.smsMaxLength);

      }
    },
    computed:{
      smsLength(){
        return this._eucKrLength(this.template.templateContent);
      },
      smsMaxLength(){
        return this.template.sendType == 'SM' ? 90 : 2000;
      }
    },
  };
</script>

<style>
</style>
