<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">알림톡 템플릿 등록</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">알림톡 템플릿 등록</h3>
            <div class="float-right">
              <base-button type="primary" @click="validateJsonData">JSON 변환</base-button>
              <base-button type="danger" @click="processRegister">등록</base-button>
            </div>
            <div class="form-group row mb-0">
              <p class="mt-4">
                <small><span class="text-primary font-weight-bold">· </span>하나의 발신프로필에 동일한 템플릿코드와 템플릿명을 중복해서 등록할 수 없습니다.</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>발신프로필 그룹 등록 시, 그룹간 동일한 템플릿 코드와 템플릿명을 중복해서 등록할 수 없습니다.</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>발신프로필과 발신프로필그룹에 동일한 템플릿코드가 존재할 경우 발신프로필에 등록된 템플릿이 우선 발송됩니다.</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>템플릿내용은 한/영 구분없이 1,000자까지 입력 가능합니다. 변수에 들어갈 내용의 최대 길이를 감안하여 작성해 주세요.</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>변수를 #{변수} 형태로 넣어주세요. (예 : #{홍길동}님의 택배가 금일 (#{09:50})에 배달될 예정입니다.)</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>버튼 등록시 버튼명은 변수 입력이 불가하며, 버튼 url에는 변수 입력이 가능합니다. (예. http://kakao.com/#{변수})</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>버튼 url 등록시 url_mobile, url_pc 링크에는 'http://', 'https://'가 포함되어야 하며 scheme_ios, scheme_android 링크는 스킴 형태에 맞게 등록해야 합니다. 그렇지 않을 경우 템플릿 등록이 불가합니다.</small><br/>
                <small><span class="text-primary font-weight-bold">· </span>OTP 등 보안이 필요한 메시지인 경우, 보안 템플릿 설정을 체크해주세요. 일반 메시지인 경우, 검수 시 설정 값이 변경될 수 있습니다.</small><br/>
                <small><span class="text-primary font-weight-bold">· </span><b>템플릿 긴급 승인건은 업무시간(10~16시) 까지 요청 해주시기 바랍니다. (kicm.dkt@kakaocorp.com)</b></small><br/>
              </p>
            </div>
          </template>
          <div>
            <form>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">발신프로필 <span class="text-danger">*</span></label>
                <div class="col-md-4" v-if="session.clientType === 'R'">
                  <base-input v-if="form.channelId"
                              placeholder="발신프로필"
                              v-model="form.channelId"
                              name="발신프로필"
                              :error="_getError('발신프로필')"
                              v-validate="'required'"
                              readonly="true">
                  </base-input>
                  <base-button type="primary" outline v-else @click="openSubChannelLayer()" style="margin-bottom: 1rem;">
                    채널ID 선택
                  </base-button>
                </div>
                <div class="col-md-10" v-else>
                  <base-input v-model="form.channelId"
                              ref="channelId"
                              name="채널아이디"
                              :error="_getError('채널아이디')"
                              v-validate="'required'">
                    <el-select v-model="form.channelId"
                               @change="changeSelect(`channelId`)"
                               filterable
                               placeholder="채널아이디 선택">
                      <el-option v-for="option in senderKeyList"
                                 :key="option.channelId"
                                 :label="`${option.sendProfileName} (${option.channelId})`"
                                 :value="option.channelId">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿코드 <span class="text-danger">*</span></label>
                <div class="col-md-4">
                  <base-input placeholder="템플릿코드 입력 (최대 30자)"
                              v-model="form.templateCode"
                              :maxlength="30"
                              name="템플릿코드"
                              :error="_getError('템플릿코드')"
                              v-validate="'required|max:30'"
                  >
                  </base-input>

                </div>
                <div class="col-md-2">
                  <base-button type="btn btn-outline-primary"  @click="makeOutoCode">코드 자동 생성</base-button>
                </div>
                <div class="col-md-4"></div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">보안템플릿</label>
                <div class="col-md-10">
                  <base-checkbox additional-classes="top-2"
                                 v-model="form.isPcExposure">
                    보안 템플릿 설정 시, 메인 디바이스 모바일 외 모든 서브 디바이스에서는 메시지 내용이 노출되지 않습니다.
                  </base-checkbox>
                </div>
              </div>
              <div class="form-group row mb-0" style="margin-top: 1rem;">
                <label class="col-md-2 col-form-label form-control-label text-center">카테고리 <span class="text-danger">*</span></label>
                <div class="col-md-2">
                  <base-input v-model="form.categoryGroup"
                              ref="categoryGroup"
                              name="카테고리그룹"
                              :error="_getError('카테고리그룹')"
                              v-validate="'required'">
                    <el-select v-model="form.categoryGroup"
                               @change="changeSelect(`categoryGroup`); getCategorySubGroup();"
                               placeholder="카테고리 그룹">
                      <el-option v-for="(option,index) in categoryList"
                                 :key="index"
                                 :label="index"
                                 :value="index">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-2">
                  <base-input v-model="form.categorySub"
                              ref="categorySub"
                              name="카테고리"
                              :error="_getError('카테고리')"
                              v-validate="'required'">
                    <el-select v-model="form.categorySub"
                               @change="changeSelect(`categorySub`); changeCategoryMsg($event);"
                               placeholder="카테고리">
                      <el-option v-for="(option,index) in categorySubList"
                                 :key="option.code"
                                 :label="option.name"
                                 :value="option.code">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <h5>{{form.categoryMsg}}</h5>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿명 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input placeholder="템플릿명 입력 (최대 200자)"
                              v-model="form.description"
                              :maxlength="200"
                              name="템플릿명"
                              :error="_getError('템플릿명')"
                              v-validate="'required|max:200'"
                  >
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">메시지유형 <span class="text-danger">*</span></label>
                <div class="col-md-4">
                  <base-input>
                    <el-select
                      v-model="form.templateMessageType"
                      @change="changeMessageType"
                      placeholder="메시지유형">
                      <el-option v-for="option in templateMessageTypeList"
                                 :key="option.label"
                                 :label="option.label"
                                 :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <label class="col-md-2 col-form-label form-control-label text-center">강조유형<span class="text-danger">*</span></label>
                <div class="col-md-4">
                  <base-input>
                    <el-select
                      v-model="form.templateEmphasizeType"
                      placeholder="강조유형">
                      <el-option v-for="option in templateEmphasizeTypeList"
                                 :key="option.label"
                                 :label="option.label"
                                 :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0" v-if="form.templateEmphasizeType === 'TEXT'">
                <label class="col-md-2 col-form-label form-control-label text-center">강조 표기 타이틀<span class="text-danger">*</span></label>
                <div class="col-md-4">
                  <base-input placeholder="강조 표기 타이틀 입력 (최대 50자)"
                              v-model="form.templateTitle"
                              :maxlength="50"
                              name="강조 표기 타이틀"
                              :error="_getError('강조 표기 타이틀')"
                              v-validate="'required|max:50'"
                  >
                  </base-input>
                </div>
                <label class="col-md-2 col-form-label form-control-label text-center">강조 표기 보조 문구<span class="text-danger">*</span></label>
                <div class="col-md-4">
                  <base-input placeholder="강조 표기 보조 문구 입력 (최대 50자)"
                              v-model="form.templateSubtitle"
                              :maxlength="50"
                              name="강조 표기 보조 문구"
                              :error="_getError('강조 표기 보조 문구')"
                              v-validate="'required|max:50'"
                  >
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0" v-if="form.templateEmphasizeType === 'IMAGE'">
                <label class="col-md-2 col-form-label form-control-label text-center">이미지<span class="text-danger">*</span></label>
                <div class="col-md-4 pb-3">
                  <dropzone-file-upload ref="dropzone" :options="dropzoneOptions" acceptedFile="image/jpeg, image/jpg, image/png" v-model="image"/>
                </div>
                <div class="col-md-6">
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 용량이 500KB 이하인 jpg, png 파일만 등록 가능합니다.</p>
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 가로 500px 이상, 세로 250px 이상이며 가로:세로 비율이 2:1 이어야 합니다.</p>
                </div>
              </div>

              <div class="form-group row mb-4" v-if="form.templateEmphasizeType === 'ITEM_LIST'">
                <label class="col-md-2 col-form-label form-control-label text-center">
                  이미지
                </label>
                <div class="col-md-10">
                  <base-checkbox v-model="templateImageYn" class="mt-1 mb-1" name="Y" :inline="true">설정</base-checkbox>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-4 pb-3" v-if="templateImageYn">
                  <dropzone-file-upload ref="dropzone" :options="dropzoneOptions" acceptedFile="image/jpeg, image/jpg, image/png" v-model="image"/>
                </div>
                <div class="col-md-5" v-if="templateImageYn">
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 용량이 500KB 이하인 jpg, png 파일만 등록 가능합니다.</p>
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 가로 500px 이상, 세로 250px 이상이며 가로:세로 비율이 2:1 이어야 합니다.</p>
                </div>
              </div>

               <div class="form-group row mb-2" v-if="form.templateEmphasizeType === 'ITEM_LIST'">
                <label class="col-md-2 col-form-label form-control-label text-center">
                  헤더
                </label>
                <div class="col-md-10">
                  <base-checkbox v-model="templateHeaderYn" class="mt-1 mb-1" name="Y" :inline="true">설정</base-checkbox>
                </div>
                 <div class="col-md-2"></div>
                <div class="col-md-10" v-if="templateHeaderYn">
                    <base-input placeholder="헤더 내용 입력 (최대 16자)"
                                v-model="form.templateHeader"
                                :maxlength="16"
                                name="헤더 내용">
                    </base-input>
                </div>
              </div>

              <div class="form-group row mb-4" v-if="form.templateEmphasizeType === 'ITEM_LIST'">
                <label class="col-md-2 col-form-label form-control-label text-center">
                  아이템 하이라이트
                </label>
                <div class="col-md-10">
                  <base-checkbox v-model="templateHighlightYn" class="mt-1 mb-1" name="Y" :inline="true">설정 (아이템 하이라이트 체크 시, 하이라이트 타이틀 및 디스크립션 설정이 필수입니다.)</base-checkbox>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5" v-if="templateHighlightYn">
                  <base-input placeholder="타이틀 입력 (최대 30자)"
                              v-model="form.templateItemHighlight.title"
                              :maxlength="highlightTitleMaxlength"
                              name="타이틀">
                  </base-input>
                </div>
                <div class="col-md-5" v-if="templateHighlightYn">
                  <base-input placeholder="디스크립션 입력 (최대 19자)"
                              v-model="form.templateItemHighlight.description"
                              :maxlength="highlightDescriptionMaxlength"
                              name="디스크립션">
                  </base-input>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-10 mb-3" v-if="templateHighlightYn">
                  <base-checkbox v-model="templateHighlightImageYn" class="mt-1 mb-1" name="Y" :inline="true">이미지 (썸네일을 추가하면 타이틀은 21자, 디스크립션은 13자까지 입력 가능합니다.)</base-checkbox>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-4" v-if="templateHighlightYn && templateHighlightImageYn">
                  <dropzone-file-upload ref="dropzone" :options="itemListDropzoneOptions" acceptedFile="image/jpeg, image/jpg, image/png" v-model="form.templateItemHighlightImage"/>
                </div>
                <div class="col-md-6" v-if="templateHighlightYn && templateHighlightImageYn">
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 용량이 500KB 이하인 jpg, png 파일만 등록 가능합니다.</p>
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 가로 108px 이상이며 가로:세로 비율이 1:1 이어야 합니다.</p>
                </div>
              </div>

              <div class="form-group row mb-4 mt-1" v-if="form.templateEmphasizeType === 'ITEM_LIST'" style="margin-top: 1.5rem;">
                <div class="col-md-2 text-center">
                  <label class="col-form-label form-control-label">
                    아이템리스트
                  </label>
                  <button type="button" class="btn base-button btn-warning btn-sm ml-2" @click.stop.self="addItemList">추가</button>
                </div>
                <div class="col-md-10">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="8%"/>
                        <col width="35%"/>
                        <col width="45%"/>
                        <col width="12%"/>
                      </colgroup>
                      <thead class="text-center">
                      <tr>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">순서</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">아이템명</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">아이템내용</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">삭제</th>
                      </tr>
                      </thead>
                      <tbody class="text-center">
                      <tr v-for="(item, index) in form.templateItem.list" :key="index">
                        <td class="align-middle">{{ index+1 }}</td>
                        <td>
                          <base-input placeholder="아이템 명 (6자 이내)"
                                      additional-classes="mb-0"
                                      v-model="item.title"
                                      :maxlength="6"
                                      :name="`아이템명${index+1}`"
                                      :error="_getError(`아이템명${index+1}`)"
                                      v-validate="'not_variable'">
                          </base-input>
                        </td>
                        <td>
                          <base-input placeholder="아이템 내용 (23자 이내)"
                                      additional-classes="mb-0"
                                      v-model="item.description"
                                      :maxlength="23"
                                      :name="`아이템내용${index+1}`">
                          </base-input>
                        </td>
                        <td class="align-middle">
                          <div>
                            <base-button
                              class="edit"
                              type="success"
                              size="sm"
                              icon
                              @click.native="moveUp(index, form.templateItem.list)">
                              <i class="text-white ni ni-bold-up align-middle"></i>
                            </base-button>
                            <base-button
                              class="edit"
                              type="success"
                              size="sm"
                              icon
                              @click.native="moveDown(index, form.templateItem.list)">
                              <i class="text-white ni ni-bold-down align-middle"></i>
                            </base-button>
                            <base-button
                              class="edit"
                              type="danger"
                              size="sm"
                              icon
                              @click.native="deleteItem(index, form.templateItem.list)">
                              <i class="text-white ni ni-fat-remove align-middle"></i>
                            </base-button>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="form.templateItem.list.length === 0">
                        <td colspan="4">아이템 리스트 정보가 없습니다.</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-2" v-if="form.templateEmphasizeType === 'ITEM_LIST'">
                <label class="col-md-2 col-form-label form-control-label text-center">
                  아이템 요약정보
                </label>
                <div class="col-md-10">
                  <base-checkbox v-model="templateSummaryYn" class="mt-1 mb-1" name="Y" :inline="true">설정 (요약정보의 아이템내용은 변수 및 화폐 단위, 숫자, 쉼표, 마침표만 사용 가능합니다.)</base-checkbox>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5" v-if="templateSummaryYn">
                  <base-input placeholder="아이템 명(최대 6자)"
                              v-model="form.templateItem.summary.title"
                              :maxlength="16"
                              name="아이템 명"
                              :error="_getError('아이템 명')"
                              v-validate="'not_variable'">
                  </base-input>
                </div>
                <div class="col-md-5" v-if="templateSummaryYn">
                  <base-input placeholder="아이템 내용 입력 (최대 14자, 화폐단위 및 숫자(쉼표 마침표 혀용) , 변수만 사용가능)"
                              v-model="form.templateItem.summary.description"
                              :maxlength="14"
                              name="아이템 내용">
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿내용 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input v-model="form.templateContent"
                              name="템플릿내용"
                              :error="_getError('템플릿내용')"
                              v-validate="'required|max:1000'">
                    <textarea v-model="form.templateContent"
                              ref="templateContent"
                              class="form-control"
                              rows="8"
                              placeholder="텍스트 입력 (최대 1000자)"
                              @keyup="keyupContent($event)"
                    ></textarea>
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0" v-if="form.templateMessageType === 'EX' || form.templateMessageType === 'MI'">
                <label class="col-md-2 col-form-label form-control-label text-center">부가 정보 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input v-model="form.templateExtra"
                              name="부가정보"
                              :error="_getError('부가정보')"
                              v-validate="'required|max:500'">
                    <textarea v-model="form.templateExtra"
                              ref="templateExtra"
                              class="form-control"
                              rows="8"
                              placeholder="텍스트 입력 (최대 500자)"
                              @keyup="keyupContent($event)"
                    ></textarea>
                  </base-input>
                </div>
              </div>

<!--              <div class="form-group row mb-0" v-if="form.templateMessageType === 'AD' || form.templateMessageType === 'MI'">-->
<!--                <label class="col-md-2 col-form-label form-control-label text-center">광고성 메시지 <span class="text-danger">*</span><br/>(수신 동의 요청 또는 간단 광고 문구)</label>-->
<!--                <div class="col-md-10">-->
<!--                  <base-input v-model="form.templateAd"-->
<!--                              name="광고성메시지"-->
<!--                              :error="_getError('광고성메시지')"-->
<!--                              v-validate="'required|max:80'">-->
<!--                    <textarea v-model="form.templateAd"-->
<!--                              ref="templateAd"-->
<!--                              class="form-control"-->
<!--                              rows="8"-->
<!--                              placeholder="텍스트 입력 (최대 80자)"-->
<!--                              @keyup="keyupContent($event)"-->
<!--                    ></textarea>-->
<!--                  </base-input>-->
<!--                </div>-->
<!--              </div>-->

              <div class="form-group row mb-0" v-if="form.templateMessageType === 'AD' || form.templateMessageType === 'MI'">
                <label class="col-md-2 col-form-label form-control-label text-center">채널 추가<br/>안내 메시지</label>
                <div class="col-md-10">
                  <base-input v-model="form.templateAd"
                              name="광고성메시지"
                              :error="_getError('광고성메시지')"
                              v-validate="'required|max:80'">
                    <textarea v-model="form.templateAd"
                              ref="templateAd"
                              class="form-control"
                              rows="2"
                              readonly="true"
                    ></textarea>
                    <span class="text-danger text-primary font-weight-bold form-control-label f-s-3">* 메시지 유형이 "채널 추가형" 혹은 "복합형"인 경우 "채널 추가" 버튼이 필수이며 메시지 하단에 채널 추가 안내 메시지가 함께 노출됩니다.<br/>
                      (발송 시점 이미 채널을 추가한 수신자에게는 채널추가 버튼과 채널추가 안내 메시지는 미노출됩니다.)<br/>
                      * 채널 추가 버튼과 부가정보를 함께 사용하고 싶으면 '복합형' 유형을 선택해주세요.</span>
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-2 text-center">
                  <label class="col-form-label form-control-label">
                    버튼
                  </label>
                  <button type="button" class="btn base-button btn-warning btn-sm ml-2" @click.stop.self="addButtons">추가</button>
                </div>
                <div class="col-md-10">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="8%"/>
                        <col width="15%"/>
                        <col width="20%"/>
                        <col/>
                        <col width="16%"/>
                      </colgroup>
                      <thead class="text-center">
                        <tr>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">순서</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">버튼타입</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">버튼명</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">버튼링크</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">삭제</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr v-for="(item, index) in buttonList" :key="index">
                          <td class="align-middle">{{ index+1 }}</td>
                          <td>
                            <base-input additional-classes="mb-0">
                              <el-select v-model="item.linkType"
                                         filterable
                                         :disabled="item.linkType==='AC'"
                                         placeholder="버튼타입 선택">
                                <el-option v-for="option in buttonTypeList"
                                           :disabled="disableAddChannelButton(index, option)"
                                           :key="option.value"
                                           :label="option.label"
                                           :value="option.value">
                                </el-option>
                              </el-select>
                            </base-input>
                          </td>
                          <td>
                            <base-input v-if="item.linkType === 'AC'"
                                        additional-classes="mb-0"
                                        value="채널 추가"
                                        readonly
                                        :maxlength="14"
                            ></base-input>
                            <base-input v-else-if="item.linkType === 'P3'"
                                        additional-classes="mb-0"
                                        value="원클릭 결제"
                                        readonly
                                        :maxlength="14"
                            ></base-input>
                            <base-input v-else placeholder="버튼명 입력 (14자 이내)"
                                        additional-classes="mb-0"
                                        v-model="item.name"
                                        :maxlength="14"
                                        :name="`버튼명${index+1}`"
                                        :error="_getError(`버튼명${index+1}`)"
                                        v-validate="'required|max:14'"
                            ></base-input>
                          </td>
                          <td>
                            <b class="text-danger" v-if="item.linkType === 'AC'">* 채널 추가 버튼은 단독 혹은 최상단에 위치한 경우에만 가능</b>
                            <b class="text-danger" v-if="item.linkType === 'AL'">* Android, iOS, Mobile 중 2가지 이상 입력 필수</b>
                            <base-input
                              v-if="item.linkType === 'AL'"
                              label="Android"
                              label-classes="small-label-type"
                              additional-classes="mt-2"
                              placeholder="scheme android 링크"
                              v-model="item.linkAnd"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'AL'"
                              label="iOS"
                              label-classes="small-label-type"
                              placeholder="scheme iOS 링크"
                              v-model="item.linkIos"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'AL' || item.linkType === 'WL'"
                              label="Mobile"
                              label-classes="small-label-type"
                              placeholder="http:// 또는 https:// 가 포함된 URL"
                              v-model="item.linkMo"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'AL' || item.linkType === 'WL'"
                              label="PC (선택)"
                              label-classes="small-label-type"
                              additional-classes="mb-0"
                              placeholder="http:// 또는 https:// 가 포함된 URL"
                              v-model="item.linkPc"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'P1' || item.linkType === 'P2' || item.linkType === 'P3'"
                              additional-classes="mb-0"
                              placeholder="플러그인 ID"
                              v-model="item.pluginId"
                            >
                            </base-input>
                          </td>
                          <td class="align-middle">
                            <div v-if="item.linkType != 'AC'">
                              <base-button
                                class="edit"
                                type="success"
                                size="sm"
                                icon
                                @click.native="moveUp(index, buttonList)">
                                <i class="text-white ni ni-bold-up align-middle"></i>
                              </base-button>
                              <base-button
                                class="edit"
                                type="success"
                                size="sm"
                                icon
                                @click.native="moveDown(index, buttonList)">
                                <i class="text-white ni ni-bold-down align-middle"></i>
                              </base-button>
                              <base-button
                                class="edit"
                                type="danger"
                                size="sm"
                                icon
                                @click.native="deleteButton(index, buttonList)">
                                <i class="text-white ni ni-fat-remove align-middle"></i>
                              </base-button>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="buttonList.length === 0">
                          <td colspan="5">버튼 정보가 없습니다.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 바로연결 영역 -->
              <div class="form-group row mb-0">
                <div class="col-md-2 text-center">
                  <label class="col-form-label form-control-label">
                    바로연결
                  </label>
                  <button type="button" class="btn base-button btn-warning btn-sm ml-2" @click.stop.self="addQuickReplies">추가</button>
                </div>
                <div class="col-md-10">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="8%"/>
                        <col width="15%"/>
                        <col width="20%"/>
                        <col/>
                        <col width="16%"/>
                      </colgroup>
                      <thead class="text-center">
                      <tr>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">순서</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">바로연결 타입</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">바로연결명</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">바로연결 링크</th>
                        <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">삭제</th>
                      </tr>
                      </thead>
                      <tbody class="text-center">
                      <tr v-for="(item, index) in quickRepliesList" :key="index">
                        <td class="align-middle">{{ index+1 }}</td>
                        <td>
                          <base-input additional-classes="mb-0">
                            <el-select v-model="item.linkType"
                                       filterable
                                       placeholder="바로연결 타입 선택">
                              <el-option v-for="option in quickRepliesTypeList"
                                         :key="option.value"
                                         :label="option.label"
                                         :value="option.value">
                              </el-option>
                            </el-select>
                          </base-input>
                        </td>
                        <td>
                          <base-input placeholder="바로연결명 입력 (14자 이내)"
                                      additional-classes="mb-0"
                                      v-model="item.name"
                                      :maxlength="14"
                                      :name="`바로연결명${index+1}`"
                                      :error="_getError(`바로연결명${index+1}`)"
                                      v-validate="'required|max:14'"
                          ></base-input>
                        </td>
                        <td>
                          <b class="text-danger" v-if="item.linkType === 'AL'">* Android, iOS, Mobile 중 2가지 이상 입력 필수</b>
                          <base-input
                            v-if="item.linkType === 'AL'"
                            label="Android"
                            label-classes="small-label-type"
                            additional-classes="mt-2"
                            placeholder="scheme android 링크"
                            v-model="item.linkAnd"
                          >
                          </base-input>

                          <base-input
                            v-if="item.linkType === 'AL'"
                            label="iOS"
                            label-classes="small-label-type"
                            placeholder="scheme iOS 링크"
                            v-model="item.linkIos"
                          >
                          </base-input>

                          <base-input
                            v-if="item.linkType === 'AL' || item.linkType === 'WL'"
                            label="Mobile"
                            label-classes="small-label-type"
                            placeholder="http:// 또는 https:// 가 포함된 URL"
                            v-model="item.linkMo"
                          >
                          </base-input>

                          <base-input
                            v-if="item.linkType === 'AL' || item.linkType === 'WL'"
                            label="PC (선택)"
                            label-classes="small-label-type"
                            additional-classes="mb-0"
                            placeholder="http:// 또는 https:// 가 포함된 URL"
                            v-model="item.linkPc"
                          >
                          </base-input>
                        </td>
                        <td class="align-middle">
                          <div>
                            <base-button
                              class="edit"
                              type="success"
                              size="sm"
                              icon
                              @click.native="moveUp(index, quickRepliesList)">
                              <i class="text-white ni ni-bold-up align-middle"></i>
                            </base-button>
                            <base-button
                              class="edit"
                              type="success"
                              size="sm"
                              icon
                              @click.native="moveDown(index, quickRepliesList)">
                              <i class="text-white ni ni-bold-down align-middle"></i>
                            </base-button>
                            <base-button
                              class="edit"
                              type="danger"
                              size="sm"
                              icon
                              @click.native="delQuickReplies(index, quickRepliesList)">
                              <i class="text-white ni ni-fat-remove align-middle"></i>
                            </base-button>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="quickRepliesList.length === 0">
                        <td colspan="5">바로연결 정보가 없습니다.</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  <!-- 채널 선택 레이어 -->
  <sub-channel-layer
    v-if="showSubChannelLayer"
    @register="registerSubChannel"
    @close="closeSubChannelLayer">
  </sub-channel-layer>

  <template-req-data-layer
    v-if="showTemplateDataLayer"
    :kakao-template-req="kakaoTemplateReq"
    @close="closeTemplateDataLayer">
  </template-req-data-layer>

  </div>
</template>

<script>
import { Table, TableColumn, Select, Option ,Upload } from 'element-ui';
import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
import commJsUtilMixin from '@/shared/mixins/commJsUtil';
import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
import { USER_API_PREFIX, RESULT_CODE, TEMPLATE_LINK_TYPE, TEMPLATE_MESSAGE_TYPE, TEMPLATE_EMPHASIZE_TYPE } from '@/shared/util/const';
import DropzoneFileUpload from '@/shared/components/Inputs/DropzoneFileUpload';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import {mapGetters} from "vuex";
import SubChannelLayer from '../Resell/SubChannelLayer'
import TemplateReqDataLayer from './TemplateReqDataLayer';
export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  mixins: [
    commPaginationMixin, commJsUtilMixin, commValidatorMixin
  ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload,
    DropzoneFileUpload,
    SubChannelLayer,
    TemplateReqDataLayer
  },
  computed : {
    ...mapGetters({
      session: 'SessionStore/getSession'
    }),
    highlightTitleMaxlength(){
      if(this.templateHighlightImageYn){
        return 21;
      }else{
        return 30;
      }
    },
    highlightDescriptionMaxlength() {
      if (this.templateHighlightImageYn) {
        return 13;
      } else {
        return 19;
      }
    }
  },
  data() {
    return {
      dropzoneOptions: {},
      itemListDropzoneOptions: {},
      image :[],
      buttonSizeNumber: 0,
      quickRepliesSizeNumber: 0,
      buttonList: [],
      quickRepliesList: [],
      senderKeyList: [],
      buttonTypeList: TEMPLATE_LINK_TYPE.BUTTON.BASIC,
      quickRepliesTypeList: TEMPLATE_LINK_TYPE.QUICK,
      templateMessageTypeList: TEMPLATE_MESSAGE_TYPE,
      templateEmphasizeTypeList: TEMPLATE_EMPHASIZE_TYPE,
      categoryList : [
        {groupName : null},
        {
          list : [{
            code : '',
            name  : '',
            groupName: '',
            inclusion:'',
            exclusion : ''
          }]
        }
      ],
      categorySubList   : [],
      form: {
        channelId: '',
        description: '',
        templateCode: '',
        templateContent: '',
        isPcExposure: true,
        templateMessageType: 'BA',
        templateExtra: null,
        templateAd: '채널 추가하고 이 채널의 광고와 마케팅 메시지 받기',
        //templateAd: null,
        templateEmphasizeType: 'NONE',
        templateTitle: null,
        templateSubtitle: null,
        categoryGroup: null,
        categorySub: null,
        categoryMsg: '카테고리 그룹, 카테고리를 모두 설정해주세요.',
        templateHeader : null,
        templateItemHighlightImage : [],
        templateItemHighlight : {
          title : null,
          description : null,
        },
        templateItem : {
          list : [
            {
             title : null,
             description : null,
            },
            {
              title : null,
              description : null,
            }
          ],
          summary : {
            title : null,
            description : null
          },
        },
        isOutoCode :false
      },
      templateImageYn : false,
      templateHeaderYn : false,
      templateHighlightYn : false,
      templateHighlightImageYn: false,
      templateSummaryYn : false,
      showSubChannelLayer : false,
      showTemplateDataLayer : false,
      kakaoTemplateReq : {},
    };
  },
  created() {
    //파일 컴포넌트 설정
    this.setDropzoneOptions();
    // 발신프로필키 목록 조회
    this.getSenderKeyList();
    // 카테고리 코드 목록 조회
    this.getCategoryList();
    // 아이템 리스트 파일 컴포넌트 설정
     this.setItemListDropzoneOptions();
    // 데이터 검증 설정
    this.customValidator();
    // 채널추가형 변경 confirm 창 표시
    this.confirmMsgTypeSelect();
  },
  methods: {
    /**
     * 이미지 컴포넌트 세팅
     */
    setDropzoneOptions() {
      const $this = this;
      this.dropzoneOptions = {
        autoProcessQueue : false,
        addRemoveLinks : true,
        addedfile: (file) => {
          let type = file.type;
          if(type.indexOf("image") < 0) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify('warning', '이미지 파일만 업로드 가능합니다.');
            return false;
          }
        },
        accept: (file, done) => {
          const fileReader = new FileReader();
          const image = new Image();
          let maxFileSize = 500 * 1024;

          fileReader.readAsDataURL(file);

          if(file.size > maxFileSize) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify('warning', '이미지 용량은 500 KB를 초과할 수 없습니다.');
            return false;
          }

          fileReader.onloadend = () => {
            if(fileReader.result) {
              image.onload = () => {
                if(!(image.width >= 500 && this.calculatorRatio(image.width, image.height , '2:1'))) { // 제한 사이즈 - 가로는 500px 이상, 세로는 250px 이상 이어야 합니다. 가로:세로 비율이 2:1 이어야 합니다.
                  $this.$refs.dropzone.dropzone.removeFile(file);
                  $this.notify('warning', '가로는 500px 이상, 세로는 250px 이상이며 가로:세로 비율이 2:1 이어야 합니다.');
                } else {
                  done();
                }
              };
              image.src = fileReader.result;
            }
          }
        }
      }
    },
    /**
     * 아이템 리스트 이미지 컴포넌트 세팅
     */
    setItemListDropzoneOptions() {
      const $this = this;
      this.itemListDropzoneOptions = {
        autoProcessQueue : false,
        addRemoveLinks : true,
        addedfile: (file) => {
          let type = file.type;
          if(type.indexOf("image") < 0) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify('warning', '이미지 파일만 업로드 가능합니다.');
            return false;
          }
        },
        accept: (file, done) => {
          const fileReader = new FileReader();
          const image = new Image();
          let maxFileSize = 500 * 1024;

          fileReader.readAsDataURL(file);

          if(file.size > maxFileSize) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify('warning', '이미지 용량은 500 KB를 초과할 수 없습니다.');
            return false;
          }

          fileReader.onloadend = () => {
            if(fileReader.result) {
              image.onload = () => {
                if(!(image.width >= 108 && this.calculatorRatio(image.width, image.height , '1:1'))) { // 제한 사이즈 - 가로는 500px 이상, 세로는 250px 이상 이어야 합니다. 가로:세로 비율이 2:1 이어야 합니다.
                  $this.$refs.dropzone.dropzone.removeFile(file);
                  $this.notify('warning', '가로는 108px 이상이며 가로:세로 비율이 1:1 이어야 합니다.');
                } else {
                  done();
                }
              };
              image.src = fileReader.result;
            }
          }
        }
      }
    },
    /**
     * 가로:세로 비율 체크
     */
    calculatorRatio(x, y , ratio) {
      if (ratio == '2:1'){
        return x === 2*y;
      }else if(ratio == '1:1'){
        return x === y;
      }
    },
    /**
     * 이미지를 업로드 하였는지 체크
     */
    checkUploadedImage() {
      if(this.form.templateEmphasizeType === 'IMAGE') { // 이미지형일 경우에만 체크
        if(this.image.length > 0) {
          return true;
        } else {
          swal({
            title: `경고`,
            text: '이미지는 필수입니다.',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning',
            type: 'warning',
            width: 400
          });
          return false;
        }
      } else {
        return true;
      }
    },
    /**
     * 아이템리스트 필수값 체크
     */
    checkItemList(){
      if(this.form.templateEmphasizeType == 'ITEM_LIST'){
        console.log(!/#\{(.*?)\}/g.test(this.form.templateItem.summary.title));
        if(this.form.isPcExposure){
          this.alertMsg('아이템 리스트는 보안 템플릿을 설정할수 없습니다.');
          return false;
        }

        if(this.form.templateItem.list.length < 2){
          this.alertMsg('아이템 리스트는 2개 이상 필수 입력 입니다.');
          return false;
        }

        for(let i=0, length = this.form.templateItem.list.length; i < length; i++) {
          if(this.form.templateItem.list[i].title == null || this.form.templateItem.list[i].title == '') {
            this.alertMsg('아이템 리스트의 아이템명은 필수 항목입니다.');
            return false;
          }

          if(this.form.templateItem.list[i].description == null || this.form.templateItem.list[i].description == '') {
            this.alertMsg('아이템 리스트의 아이템내용은 필수 항목입니다.');
            return false;
          }
        }

        let chkItemHighlight = true;
        if (this.form.templateItemHighlight.title == null || this.form.templateItemHighlight.description == null) {
          chkItemHighlight = false;
        }

        let chkTemplateItemSummary = true;
        if(this.form.templateItem.summary.title == null || this.form.templateItem.summary.description == null){
          chkTemplateItemSummary = false;
        }

        if(this.templateImageYn && this.image.length <=0){
          this.alertMsg('이미지를 선택해주세요.');
          return false;
        }

        if(this.templateHeaderYn && this.form.templateHeader == null){
          this.alertMsg('헤더를 입력해주세요.');
          return false;
        }

        if(this.templateHighlightYn && !chkItemHighlight){
          this.alertMsg('아이템를 하이라이트 입력해주세요.');
          return false;
        }else if(this.templateHighlightYn && this.templateHighlightImageYn){
          if(this.form.templateItemHighlightImage.length <= 0){
            this.alertMsg('아이템 하이라이트 썸네일 이미지를 선택해주세요');
            return false;
          }
        }

        if(this.templateSummaryYn && !chkTemplateItemSummary){
          this.alertMsg('요약정보를 입력해주세요.');
          return false;
        }


        if(this.image.length <= 0 && this.form.templateHeader == null && !chkItemHighlight){
          this.alertMsg('이미지,헤더,아이템 하이라이트 중 한가지는 필수입니다.');
          return false;
        }
      }
      return true;
    },
    /**
     * 발신프로필키 목록 조회
     */
    getSenderKeyList() {
      this.$axios.get(`${USER_API_PREFIX}/template/channel`, {
        params: {}
      }).then(res => {
        this.senderKeyList = res.data.result.channelList;
      });
    },
    /**
     * 카테고리 목록 조회
     */
    getCategoryList() {
      this.$axios.get(`${USER_API_PREFIX}/template/category/all`).then(res => {
        if (res.data.result.code === RESULT_CODE.SUCCESS) {
          this.categoryList = this.groupArrayOfObjects(res.data.result.res.data,"groupName");
        }else {
          this.notify('warning', res.data.result.message);
        }
      });
    },
    /**
     * 카테고리 그룹 선택 시 이벤트
     */
    getCategorySubGroup() {
      this.changeCategoryMsg();
      this.form.categorySub = null;
      this.categorySubList = this.categoryList[this.form.categoryGroup];
    },
    /**
     * 카테고리 그룹핑
     */
    groupArrayOfObjects(list, key) {
      return list.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    /**
     * 카테고리 메시지
     */
    changeCategoryMsg(event){
      if(null == event || this.form.categoryGroup == null || this.form.categorySub == null) {
        this.form.categoryMsg = '카테고리 그룹, 카테고리를 모두 설정해주세요.';
      } else {
        for(let i=0, length=this.categorySubList.length; i<length; i++) {
          if(this.categorySubList[i].code === event.toString()) {
            this.form.categoryMsg = this.categorySubList[i].inclusion;
          }
        }
      }
    },
    /**
     * 템플릿 정보 등록
     */
    processRegister() {
      this._validateBeforeSubmit(this.register, this.errorCallback);
    },
    /**
     * 저장 로직 실행
     */
    register() {
      let channelChk = true;
      if(this.form.channelId === '') {
        swal({
          title: `경고`,
          text: '발신프로필을 선택해주세요.',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning',
          type: 'warning',
          width: 400
        });
        channelChk = false;
      }

      if(!this.checkValidateButtonInfo() && this.checkUploadedImage() && this.checkItemList() && channelChk && this.checkTextTitle()) {
        this._swalQuestion({ title:'등록 하시겠습니까?' }, () => {
          let data = {
            channelId: this.form.channelId,
            kkoTemplateCd: this.form.templateCode,
            description: this.form.description,
            templateContent: this.form.templateContent,
            pcExposureYn: this.form.isPcExposure ? 'Y' : 'N',
            templateButtonList: this.setTemplateButtonsParam(),
            templateQuickRepliesList: this.setTemplateQuickRepliesParam(),
            templateMessageType: this.form.templateMessageType,
            templateExtra: (this.form.templateMessageType === 'EX' || this.form.templateMessageType === 'MI') ? this.form.templateExtra : null,
            templateAd: (this.form.templateMessageType === 'AD' || this.form.templateMessageType === 'MI') ? this.form.templateAd : null,
            templateEmphasizeType: this.form.templateEmphasizeType,
            templateTitle: this.form.templateEmphasizeType === 'TEXT' ? this.form.templateTitle : null,
            templateSubtitle: this.form.templateEmphasizeType === 'TEXT' ? this.form.templateSubtitle : null,
            categoryCd : this.form.categorySub,
            templateHeader  : (this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateHeaderYn === true) ? this.form.templateHeader : null,
            templateItemHighlight : (this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateHighlightYn === true) ?  this.form.templateItemHighlight : null,
            templateItem : this.form.templateEmphasizeType === 'ITEM_LIST' ? this.form.templateItem : null
          }

          if (this.form.templateEmphasizeType === 'IMAGE') {
            data.templateImageData = this.image[0].dataURL;
            data.originImageName = this.image[0].name;
          }else if(this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateImageYn && this.image.length > 0){
            data.templateImageData = this.image[0].dataURL;
            data.originImageName = this.image[0].name;
          }

          if(this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateHighlightImageYn && this.form.templateItemHighlightImage.length > 0){
            data.itemHighlightImageData = this.form.templateItemHighlightImage[0].dataURL;
            data.itemHighlightImageName = this.form.templateItemHighlightImage[0].name;
          }

          if(this.form.templateEmphasizeType === 'ITEM_LIST' && !this.templateSummaryYn){
            let templateItem = JSON.parse(JSON.stringify(this.form.templateItem));
            templateItem.summary = null;
            data.templateItem = templateItem;
          }

          this.$axios.post(`${USER_API_PREFIX}/template`, data).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.notify('default', res.data.result.message);
              this.$router.push('list');
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      }
    },
    /**
     * 강조 표기 타이틀 및 표기 보조문구 템플릿내용 포함여부 체크
     */
    checkTextTitle(){
      if(this.form.templateEmphasizeType === 'TEXT'){
        if(this.form.templateTitle == null){
          this.alertMsg('강조 표기 타이틀 및 문구를 입력하세요.');
          return false;
        }else{
        /**
         * 23.02.06 강조표기형 Validation 삭제 - harvey.bae
         */
          return true;
          // if(this.form.templateContent.indexOf(this.form.templateTitle.trim()) != -1){
          //     return true;
          // }else{
          //   this.alertMsg('강조 표기 타이틀 설정시 본문에 동일한 문구가 필요합니다.');
          //   return false;
          // }
        }
      }else{
        return true;
      }
    },
    /**
     * 버튼 정보의 유효성 체크
     */
    checkValidateButtonInfo() {
      let isError = false;
      // 버튼 유효성 체크 진행
      if(this.buttonList.length > 0) {
        this.buttonList.some((button, idx) => {
          if(button.linkType === '') {
            swal({
              title: `경고`,
              text: '버튼 타입을 선택해주세요.',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              type: 'warning',
              width: 400
            });
            isError = true;
          } else if(button.linkType === 'WL') {
            if(!button.linkMo) {
              swal({
                title: `경고`,
                text: '웹링크의 Mobile 항목은 필수 정보입니다.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
          } else if(button.linkType === 'AL') {
            if( (!button.linkAnd && !button.linkIos) || (!button.linkAnd && !button.linkMo) || (!button.linkIos && !button.linkMo) ) {
              swal({
                title: `경고`,
                text: '앱링크는 Android, iOS, Mobile 중 2가지 이상 필수 입력입니다.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
          } else if(button.linkType === 'AC') {
            if(this.form.templateMessageType !== 'AD' && this.form.templateMessageType !== 'MI') {
              swal({
                title: `경고`,
                text: '채널추가는 메시지유형이 채널추가형 이거나 복합형 일 경우에만 사용 가능합니다.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
            if(idx > 0) {
              swal({
                title: `경고`,
                text: '채널추가는 단독 혹은 최상단에 위치한 경우에만 사용 가능합니다.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
          } else if(button.linkType === 'P1' || button.linkType === 'P2' || button.linkType === 'P3') {
            if(!button.pluginId) {
              swal({
                title: `경고`,
                text: '플러그인 ID를 입력해주세요.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
          }
          return isError;
        });
      }

      // 바로연결 유효성 체크 진행
      if(this.quickRepliesList.length > 0) {
        this.quickRepliesList.some((button, idx) => {
          if(button.linkType === '') {
            swal({
              title: `경고`,
              text: '바로연결 타입을 선택해주세요.',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              type: 'warning',
              width: 400
            });
            isError = true;
          } if(button.linkType === 'WL') {
            if(!button.linkMo) {
              swal({
                title: `경고`,
                text: '웹링크의 Mobile 항목은 필수 정보입니다.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
          } else if(button.linkType === 'AL') {
            if( (!button.linkAnd && !button.linkIos) || (!button.linkAnd && !button.linkMo) || (!button.linkIos && !button.linkMo) ) {
              swal({
                title: `경고`,
                text: '앱링크는 Android, iOS, Mobile 중 2가지 이상 필수 입력입니다.',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning',
                type: 'warning',
                width: 400
              });
              isError = true;
            }
          }
          return isError;
        });
      }

      return isError;
    },
    /**
     * 버튼 파라미터 정보 세팅
     */
    setTemplateButtonsParam() {
      let templateButtonList = [];
      this.buttonList.forEach((button, idx) => {
        let obj = {};
        obj.name = button.name;
        obj.linkType = button.linkType;
        obj.ordering = idx+1;

        if(button.linkType === 'WL') {
          if(button.linkMo)
            obj.linkMo = button.linkMo;
          if(button.linkPc)
            obj.linkPc = button.linkPc;
        } else if(button.linkType === 'AL') {
          if(button.linkAnd)
            obj.linkAnd = button.linkAnd;
          if(button.linkIos)
            obj.linkIos = button.linkIos;
          if(button.linkMo)
            obj.linkMo = button.linkMo;
          if(button.linkPc)
            obj.linkPc = button.linkPc;
        } else if(button.linkType === 'AC') {
          obj.name = '채널 추가';
        } else if(button.linkType === 'P3') {
          obj.name = '원클릭 결제';
          obj.pluginId = button.pluginId;
        } else if(button.linkType === 'P1' || button.linkType === 'P2') {
          obj.pluginId = button.pluginId;
        }
        templateButtonList.push(obj);
      });

      return templateButtonList;
    },
    /**
     * 바로연결 파라미터 정보 세팅
     */
    setTemplateQuickRepliesParam() {
      let templateQuickRepliesList = [];
      this.quickRepliesList.forEach((quickReplies, idx) => {
        let obj = {};
        obj.name = quickReplies.name;
        obj.linkType = quickReplies.linkType;
        obj.ordering = idx+1;

        if(quickReplies.linkType === 'WL') {
          if(quickReplies.linkMo)
            obj.linkMo = quickReplies.linkMo;
          if(quickReplies.linkPc)
            obj.linkPc = quickReplies.linkPc;
        } else if(quickReplies.linkType === 'AL') {
          if(quickReplies.linkAnd)
            obj.linkAnd = quickReplies.linkAnd;
          if(quickReplies.linkIos)
            obj.linkIos = quickReplies.linkIos;
          if(quickReplies.linkMo)
            obj.linkMo = quickReplies.linkMo;
          if(quickReplies.linkPc)
            obj.linkPc = quickReplies.linkPc;
        }
        templateQuickRepliesList.push(obj);
      });

      return templateQuickRepliesList;
    },
    /**
     * 필수요소 중, 셀렉트박스 및 텍스트박스 값이 없을 경우 invalid 강조표시를 하기 위한 콜백함수
     * '발신프로필키', '템플릿내용' 항목에 대하여 border color를 변경한다.
     */
    errorCallback() {
      this.errors.items.forEach(item => {
        if(item.field === '발신프로필') {
          this.$refs.channelId.$el.getElementsByClassName('el-input__inner')[0].style.borderColor = '#fb6340';
        }
        if(item.field === '템플릿내용') {
          this.$refs.templateContent.style.borderColor = '#fb6340';
        }
        if(item.field === '부가정보') {
          this.$refs.templateExtra.style.borderColor = '#fb6340';
        }
        if(item.field === '광고성메시지') {
          this.$refs.templateAd.style.borderColor = '#fb6340';
        }
        if(item.field === '카테고리그룹') {
          this.$refs.categoryGroup.$el.getElementsByClassName('el-input__inner')[0].style.borderColor = '#fb6340';
        }
        if(item.field === '카테고리') {
          this.$refs.categorySub.$el.getElementsByClassName('el-input__inner')[0].style.borderColor = '#fb6340';
        }
      });
    },
    /**
     * 셀렉트박스를 선택했을 때, invalid 강조 표시를 제거한다.
     */
    changeSelect(refId, idx) {
      if(idx) {
        this.$refs[refId][idx].$el.getElementsByClassName('el-input__inner')[0].style.borderColor = '#DCDFE6';
      } else {
        this.$refs[refId].$el.getElementsByClassName('el-input__inner')[0].style.borderColor = '#DCDFE6';
      }
    },
    /**
     * 템플릿내용 invalid 강조 및 제거 이벤트
     */
    keyupContent(event) {
      if(this.errors.has('템플릿내용')) {
        this.$refs.templateContent.style.borderColor = '#fb6340';
      } else {
        this.$refs.templateContent.style.borderColor = '#DCDFE6';
      }

      if(this.$refs.templateExtra !== undefined) {
        if(this.errors.has('부가정보')) {
          this.$refs.templateExtra.style.borderColor = '#fb6340';
        } else {
          this.$refs.templateExtra.style.borderColor = '#DCDFE6';
        }
      }

      if(this.$refs.templateAd !== undefined) {
        if(this.errors.has('광고성메시지')) {
          this.$refs.templateAd.style.borderColor = '#fb6340';
        } else {
          this.$refs.templateAd.style.borderColor = '#DCDFE6';
        }
      }
    },
    /**
     * 버튼 추가 이벤트
     */
    addButtons() {
      // 바로연결이 설정되어 있으면, 버튼은 최대 2개까지만 가능하다.
      if(this.quickRepliesList.length > 0 && this.buttonList.length > 1) {
        swal({
          title: `바로연결`,
          text: `바로연결 설정 시, 버튼은 최대 2개까지 설정 가능합니다.`,
          confirmButtonText: '확인',
          type: 'warning'
        });
        return false;
      }

      if(this.buttonSizeNumber < 5) {
        this.buttonSizeNumber++;
        this.buttonList.push({
          name: '',
          linkType: '',
          linkMo: '',
          linkPc: '',
          linkAnd: '',
          linkIos: ''
        });
      }
    },
    /**
     * 바로연결 추가 이벤트
     */
    addQuickReplies() {
      // 버튼이 3개 이상 설정되어 있으면 바로연결을 추가할 수 없다.
      if(this.buttonList.length > 2) {
        swal({
          title: `바로연결`,
          text: `바로연결 설정 시, 버튼은 최대 2개까지 설정 가능합니다.`,
          confirmButtonText: '확인',
          type: 'warning'
        });
        return false;
      }

      if(this.quickRepliesSizeNumber < 10) {
        this.quickRepliesSizeNumber++;
        this.quickRepliesList.push({
          name: '',
          linkType: '',
          linkMo: '',
          linkPc: '',
          linkAnd: '',
          linkIos: ''
        });
      }
    },
    /**
     * 바로연결 제거
     * @param rowKey
     * @param list
     */
    delQuickReplies(rowKey, list) {
      list.splice(rowKey, 1);
      this.quickRepliesSizeNumber--;
    },
    /**
     * 아이템리스트 추가 이벤트
     */
    addItemList() {
      if(this.form.templateItem.list.length < 10) {
        this.form.templateItem.list.push({
          title : null,
          description : null,
        });
      }
    },
    /**
     * 아이템리스트 제거
     * @param rowKey
     * @param list
     */
    deleteItem(rowKey, list) {
      if(list.length <= 2){
        swal({
          title: `아이템 리스트`,
          text: `아이템 리스트는 최소 2개이상 설정 가능합니다.`,
          confirmButtonText: '확인',
          type: 'warning'
        });
        return false;
      }else{
        list.splice(rowKey, 1);
      }
    },
    /**
     * 버튼 순서 위로
     * @param rowKey
     * @param list
     */
    moveUp(rowKey, list) {
      list.splice(rowKey - 1, 0, list.splice(rowKey, 1)[0]);
    },
    /**
     * 버튼 순서 아래로
     * @param rowKey
     * @param list
     */
    moveDown(rowKey, list) {
      list.splice(rowKey + 1, 0, list.splice(rowKey, 1)[0]);
    },
    /**
     * 버튼 제거
     * @param rowKey
     * @param list
     */
    deleteButton(rowKey, list) {
      list.splice(rowKey, 1);
      this.buttonSizeNumber--;
    },
    /**
     * 메시지 유형 변경 시, 버튼 유형 목록을 변경
     */
    changeMessageType() {
      let type;
      switch (this.form.templateMessageType) {
        case 'AD':
          type = TEMPLATE_LINK_TYPE.BUTTON.ADV;
          if(this.buttonSizeNumber === 0){
            this.buttonSizeNumber++;
            this.buttonList.unshift({
              linkType: 'AC'
            });
          }else{
            swal({
              title: `경고`,
              text: '메시지 유형이 "채널 추가형"으로 변경되어, 첫번째 버튼의 타입이 "채널추가"로 변경 입력되며 기존 입력한 버튼정보는 유지되지 않습니다.',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              type: 'warning',
              width: 400
            });
            this.buttonList.forEach((item, index) => {
              if(index === 0){
                item.linkType = 'AC';
              }else{
                item.linkType = '';
              }
            });
          }
          break;
        case 'MI':
          type = TEMPLATE_LINK_TYPE.BUTTON.ADV;
          if(this.buttonSizeNumber === 0){
            this.buttonSizeNumber++;
            this.buttonList.unshift({
              linkType: 'AC'
            });
          }else{
            swal({
              title: `경고`,
              text: '메시지 유형이 "복합형"으로 변경되어, 첫번째 버튼의 타입이 "채널추가"로 변경 입력되며 기존 입력한 버튼정보는 유지되지 않습니다.',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              type: 'warning',
              width: 400
            });
            this.buttonList.forEach((item, index) => {
              if(index === 0){
                item.linkType = 'AC';
              }else{
                item.linkType = '';
              }
            });
          }
          break;
        default :
          type = TEMPLATE_LINK_TYPE.BUTTON.BASIC;
          // 메시지유형이 기본 or 부가정보형을 선택하게 되면 기존에 설정되있던 채널추가 버튼을 초기화시킨다.
          for (let i=0; i<this.buttonList.length; i++) {
            if ('AC' === this.buttonList[i].linkType) {
              this.buttonList[i].linkType = '';
              this.buttonList[i].name = '';
            }
          }
          break;
      }
      this.buttonTypeList = type;
    },
    /**
     * 채널 추가 버튼에 대하여 최상단에 위치하였는지 여부 반환
     */
    disableAddChannelButton(idx, option) {
      return idx>0 && option.value === 'AC';
    },
    /**
     * 채널 레이어 닫기
     */
    openSubChannelLayer() {
      this.showSubChannelLayer = true
    },

    /**
     * 채널 레이어 닫기
     */
    closeSubChannelLayer() {
      this.showSubChannelLayer = false
    },

    /**
     * 채널 선택
     */
    registerSubChannel(channelId){
      this.form.channelId = channelId;
      // this.selectMsgType();
    },

    /**
     * 자동코드 설정
     */
    makeOutoCode(){
      this.$axios.get(`${USER_API_PREFIX}/template/outo-code`, {
        params: {}
      }).then(res => {
        this.form.templateCode = res.data.result.templateCode;
      });
    },

    validateJsonData(){
      this._validateBeforeSubmit(this.showJsonLayer, this.errorCallback);
    },

    /**
     * JSON 변환 레이어 열기
     */
    showJsonLayer(){
      let channelChk = true;
      if(this.form.channelId === '') {
        swal({
          title: `경고`,
          text: '발신프로필을 선택해주세요.',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning',
          type: 'warning',
          width: 400
        });
        channelChk = false;
      }

      if(!this.checkValidateButtonInfo() && this.checkUploadedImage() && this.checkItemList() && channelChk) {
        let data = {
          channelId: this.form.channelId,
          kkoTemplateCd: this.form.templateCode,
          description: this.form.description,
          templateContent: this.form.templateContent,
          pcExposureYn: this.form.isPcExposure ? 'Y' : 'N',
          templateButtonList: this.setTemplateButtonsParam(),
          templateQuickRepliesList: this.setTemplateQuickRepliesParam(),
          templateMessageType: this.form.templateMessageType,
          templateExtra: (this.form.templateMessageType === 'EX' || this.form.templateMessageType === 'MI') ? this.form.templateExtra : null,
          templateAd: (this.form.templateMessageType === 'AD' || this.form.templateMessageType === 'MI') ? this.form.templateAd : null,
          templateEmphasizeType: this.form.templateEmphasizeType,
          templateTitle: this.form.templateEmphasizeType === 'NONE' ? null : this.form.templateTitle,
          templateSubtitle: this.form.templateEmphasizeType === 'NONE' ? null : this.form.templateSubtitle,
          categoryCd : this.form.categorySub,
          templateHeader  : (this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateHeaderYn === true) ? this.form.templateHeader : null,
          templateItemHighlight : (this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateHighlightYn === true) ?  this.form.templateItemHighlight : null,
          templateItem : this.form.templateEmphasizeType === 'ITEM_LIST' ? this.form.templateItem : null
        }

        if (this.form.templateEmphasizeType === 'IMAGE') {
          data.templateImageData = this.image[0].dataURL;
          data.originImageName = this.image[0].name;
        }else if(this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateImageYn && this.image.length > 0){
          data.templateImageData = this.image[0].dataURL;
          data.originImageName = this.image[0].name;
        }

        if(this.form.templateEmphasizeType === 'ITEM_LIST' && this.templateHighlightImageYn && this.form.templateItemHighlightImage.length > 0){
          data.itemHighlightImageData = this.form.templateItemHighlightImage[0].dataURL;
          data.itemHighlightImageName = this.form.templateItemHighlightImage[0].name;
        }


        this.$axios.post(`${USER_API_PREFIX}/template/json`, data).then(res => {
          if (res.data.result.code === RESULT_CODE.SUCCESS) {
             this.kakaoTemplateReq = res.data.result.kakaoTemplateReq
             this.showTemplateDataLayer = true;
          } else {
            this.notify('warning', res.data.result.message);
          }
        });
      }
    },
    /**
     * JSON 변환 레이어 닫기
     */
    closeTemplateDataLayer() {
      this.showTemplateDataLayer = false
    },
    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      });
    },
    /**
     * 알럿메시지 출력
     */
    alertMsg(msg , title){
      let titleMsg = title ? title : '경고';
      let type = title ? 'info' : 'warning';
      swal({
        title: titleMsg,
        text: msg,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-'+type,
        type: type,
        width: 400
      });
    },
    /**
     * 발신프로필 선택 시 채널추가형으로 메시지 유형 자동 변경
     * 고객사 혼동으로 인하여 차후 적용 예정
     */
    selectMsgType(){
      this.form.templateMessageType = 'AD';
      this.changeMessageType();
    },
    /**
     * Vee-Validator 커스텀
     */
    customValidator(){
      this.$validator.extend('not_variable', {
        getMessage: field => field + '에는 변수를 넣을 수 없습니다.',
        validate: value => !/#\{(.*?)\}/g.test(value)
      });
    },
    /**
     * 채널추가형 변경 여부 confirm
     */
    confirmMsgTypeSelect(){
      swal({
        title: `정책 변경 알림`,
        html: '카카오 정책에 따라 알림톡 템플릿 등록시 기본 템플릿 타입이<br/> “기본형”에서 “채널추가형”으로 변경됩니다. <br/>' +
          '채널추가형은 메시지하단에 “채널추가” 버튼이 기본으로 설정되어 <br/>손쉽게 채널 추가가 가능합니다.<br/><br/>' +
          '확인을 선택하시면 “채널추가형”으로, <br/>“취소”를 선택하면 기존 “기본형”으로 템플릿 작성이 가능합니다.',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        reverseButtons: true
      }).then((result) => {
        if(result.value) {
          this.selectMsgType();
        }
      });
    }
  }
};
</script>
<style scoped>
  .no-border-card .card-footer{
    border-top: 0;
  }
  .f-s-3 {
    font-size: 0.75rem;
  }
  .f-s-4 {
    font-size: 1rem;
  }
</style>
