<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">알림톡 템플릿 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">알림톡 템플릿 목록</h3>
            <div class="float-right">
              <base-button type="primary" @click="requestInspectionTemplate">선택 템플릿 검수요청</base-button>
              <base-button type="primary" @click="excelDownloadTemplate">선택 템플릿 다운로드</base-button>
              <base-button type="danger" @click="requestDeleteTemplate">선택 템플릿 삭제</base-button>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-between">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <div class="col-lg-2 pr-0" v-if="session.clientType === 'R'">
                  <el-select v-model="search.channelId"
                             filterable
                             placeholder="채널"
                             @change="getList(1)">
                    <el-option v-for="option in channelOptions"
                               :key="option.channelId"
                               :label="option.channelName"
                               :value="option.channelId">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-2 pr-0" v-else></div>

                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.kepStatus"
                             filterable
                             placeholder="검수상태"
                             @change="getList(1)"
                  >
                    <el-option v-for="option in kepStatusOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.templateDormant"
                             filterable
                             placeholder="휴면상태"
                             @change="getList(1)"
                  >
                    <el-option v-for="option in dormantOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.templateBlock"
                             filterable
                             placeholder="차단상태"
                             @change="getList(1)"
                  >
                    <el-option v-for="option in blockOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.condition"
                             filterable
                             placeholder="검색 조건">
                    <el-option v-for="option in selectOptions"
                               :key="option.label"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-lg-2 pl-1 pr-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="검색어 입력" aria-label="검색어 입력" aria-describedby="button-addon2"
                           v-model="search.keyword"
                           @keydown.enter="getList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="getList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <el-table :data="templateList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'등록된 템플릿이 없습니다.'"
                      class="table-responsive table-flush"
                      @selection-change="onSelectionChange">
              <el-table-column type="selection"
                               align="left">
              </el-table-column>

              <el-table-column prop="channelId" label="발신프로필" min-width="10%"></el-table-column>
              <el-table-column prop="kkoTemplateCd" label="템플릿코드" min-width="10%"></el-table-column>
              <el-table-column prop="description" label="템플릿명" min-width="10%">
                <template slot-scope="scope">
                  <router-link :to="'view/' + scope.row.kkoSeqno + '?referer=' + $route.path + params" class="link_detail link_txt emph_g">
                    {{ scope.row.description }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="templateContent" label="메시지내용" min-width="20%">
                <template slot-scope="scope">
                  <router-link :to="'view/' + scope.row.kkoSeqno + '?referer=' + $route.path + params" class="link_detail link_txt emph_g">
                    {{ scope.row.templateContent }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="kepStatus" label="검수상태" min-width="8%">
                <template slot-scope="scope">{{ scope.row.kepStatus | kepStatusFilter }}</template>
              </el-table-column>
              <el-table-column prop="templateDormant" label="휴면상태" min-width="7%">
                <template slot-scope="scope">{{ scope.row.templateDormant | dormantFilter }}</template>
              </el-table-column>
              <el-table-column prop="templateBlock" label="차단상태" min-width="7%">
                <template slot-scope="scope">{{ scope.row.templateBlock | blockFilter }}</template>
              </el-table-column>
              <el-table-column prop="pcExposureYn" label="보안템플릿" min-width="8%">
                <template slot-scope="scope">{{ scope.row.pcExposureYn === 'Y' ? '설정' : '미설정' }}</template>
              </el-table-column>
              <el-table-column prop="regDate" label="등록일시" min-width="12%">
                <template slot-scope="scope">{{ scope.row.regDate | convertDatetimeFormat }}</template>
              </el-table-column>
            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="getList">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const';
  import swal from 'sweetalert2';

  export default {
    mixins: [
      commPaginationMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      }),
      params() {
        return '&page=' + this.pagination.currentPage + '&perPage=' + this.pagination.perPage + '&condition=' + this.search.condition + '&keyword=' + this.search.keyword
          + '&kepStatus=' + this.search.kepStatus + '&channelId=' + this.search.channelId + '&templateDormant=' + this.search.templateDormant;
      },
    },
    filters: {
      kepStatusFilter: value => {
        let name = '';
        switch (value) {
          case 'N':
            name = '등록';
            break;
          case 'I':
            name = '검수요청';
            break;
          case 'O':
            name = '승인';
            break;
          case 'R':
            name = '반려';
            break;
        }
        return name;
      },
      blockFilter: value => {
        return value ? '차단' : '해제';
      },
      dormantFilter: value => {
        return value ? '휴면' : '해제';
      },
    },
    created() {
      if(this.$route.query.page) this.pagination.currentPage = Number(this.$route.query.page);
      if(this.$route.query.perPage) this.pagination.perPage = Number(this.$route.query.perPage);
      if(this.$route.query.condition) this.search.condition = this.$route.query.condition;
      if(this.$route.query.keyword) this.search.keyword = this.$route.query.keyword;
      if(this.$route.query.kepStatus) this.search.kepStatus = this.$route.query.kepStatus;
      if(this.$route.query.channelId) this.search.channelId = this.$route.query.channelId;
      if(this.$route.query.templateDormant) this.search.templateDormant = JSON.parse(this.$route.query.templateDormant);

      // 목록 조회
      this.getList(this.pagination.currentPage);

      // 재판 고객사 인 경우, 하위 채널 목록 조회
      if (this.session.clientType === 'R') {
        this.retrieveChannelList();
      }
    },
    data() {
      return {
        showLayer: false,
        templateList: [],
        selectedRows: [],
        senderNo: undefined,
        search: {
          condition: 'DESCRIPTION',
          keyword: '',
          kepStatus: '',
          templateDormant: '',
          templateBlock: '',
          channelId : ''
        },
        selectOptions: [
          {
            label: '템플릿명',
            value: 'DESCRIPTION'
          },
          {
            label: '템플릿코드',
            value: 'KKO_TEMPLATE_CD'
          }
        ],
        kepStatusOptions: [
          {
            label: '== 검수상태 ==',
            value: ''
          }, {
            label: '등록',
            value: 'N'
          }, {
            label: '검수요청',
            value: 'I'
          }, {
            label: '승인',
            value: 'O'
          }, {
            label: '반려',
            value: 'R'
          }
        ],
        dormantOptions: [
          {
            label: '== 휴면상태 ==',
            value: ''
          }, {
            label: '휴면',
            value: true
          }, {
            label: '휴면해제',
            value: false
          }
        ],
        blockOptions: [
          {
            label: '== 차단상태 ==',
            value: ''
          }, {
            label: '차단',
            value: true
          }, {
            label: '차단해제',
            value: false
          }
        ],
        channelOptions: []
      };
    },
    methods: {
      /**
       * 목록 조회
       */
      getList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/template`, {
          params: {
            page: pageNo,
            rows: Number(this.pagination.perPage),
            searchCondition: this.search.condition,
            searchKeyword: this.search.keyword,
            kepStatus: this.search.kepStatus,
            templateDormant: this.search.templateDormant,
            templateBlock: this.search.templateBlock,
            channelId: this.search.channelId
          }
        }).then(res => {
          if(res.data.result.templateList != null) {
            this.templateList = res.data.result.templateList; // 목록정보 세팅
            this.pagination.currentPage = pageNo; // 페이지 번호 세팅
            this.pagination.total = res.data.result.total; // 전체페이지수 세팅
          }
        });
      },
      /**
       * 템플릿 삭제 요청
       */
      requestDeleteTemplate() {
        if(this.selectedRows.length === 0) {
          swal({
            title: `템플릿 삭제`,
            text: `삭제할 템플릿을 선택해주세요.`,
            confirmButtonText: '확인',
            type: 'warning'
          });
          return false;
        }

        swal({
          title: `선택한 템플릿을 삭제하시겠습니까?`,
          text: '검수상태가 "등록" 또는 "반려"이고 템플릿상태가 "대기"인 템플릿만 삭제됩니다.',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            // 템플릿 코드 목록 파싱
            let templateList = [];
            this.selectedRows.forEach(val => {
              templateList.push({
                kkoTemplateCd: val.kkoTemplateCd,
                channelId: val.channelId,
              });
            });

            // 삭제 요청
            this.$axios.post(`${USER_API_PREFIX}/template/remove`, {
              templateList: templateList
            }).then(res => {
              if (res.data.result.code === RESULT_CODE.SUCCESS) {
                let jobCnt = res.data.result.jobCnt;
                let failCnt = res.data.result.failCnt;
                let failureTemplateList = res.data.result.failureTemplateList;
                swal({
                  title: `[템플릿 삭제 진행결과]`,
                  text: `템플릿수( ${jobCnt} ), 성공건수( ${jobCnt - failCnt} ), 실패건수( ${failCnt} )\n\n실패한 템플릿: ${failureTemplateList}`,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success',
                  type: 'success'
                }).then(res => {
                  if(result.value) {
                    this.getList();
                  }
                });
              } else {
                this.notify('warning', res.data.result.message);
              }
            });
          }
        });
      },
      /**
       * 템플릿 검수 요청 진행
       */
      requestInspectionTemplate() {
        if(this.selectedRows.length === 0) {
          swal({
            title: `템플릿 검수요청`,
            text: `검수요청할 템플릿을 선택해주세요.`,
            confirmButtonText: '확인',
            type: 'warning'
          });
          return false;
        }

        swal({
          title: `선택한 템플릿을 검수요청 하시겠습니까?`,
          text: '검수상태가 "등록" 이고, 템플릿상태가 "대기"인 템플릿만 검수요청 됩니다.',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            // 템플릿 코드 목록 파싱
            let templateList = [];
            this.selectedRows.forEach(val => {
              templateList.push({
                kkoTemplateCd: val.kkoTemplateCd,
                channelId: val.channelId,
              });
            });

            // 검수 요청
            this.$axios.post(`${USER_API_PREFIX}/template/request/inspection`, {
              templateList: templateList
            }).then(res => {
              if (res.data.result.code === RESULT_CODE.SUCCESS) {
                let jobCnt = res.data.result.jobCnt;
                let failCnt = res.data.result.failCnt;
                let failureTemplateList = res.data.result.failureTemplateList;
                swal({
                  title: `[템플릿 검수요청 진행결과]`,
                  text: `템플릿수( ${jobCnt} ), 성공건수( ${jobCnt - failCnt} ), 실패건수( ${failCnt} )\n\n실패한 템플릿: ${failureTemplateList}`,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success',
                  type: 'success'
                }).then(res => {
                  if(result.value) {
                    this.getList();
                  }
                });
              } else {
                this.notify('warning', res.data.result.message);
              }
            });
          }
        });
      },
      /**
       * 채널아이디 정보 조회
       */
      retrieveChannelList() {
        this.channelList = [];
        this.$axios.get(`${USER_API_PREFIX}/sender/channel-list`).then((res) => {
          if(res.data.result.code === RESULT_CODE.SUCCESS) {
            this.channelOptions = res.data.result.channelList;
            this.channelOptions.unshift({
              channelId: '',
              channelName: '== 채널 =='
            })
          }
        }).catch((error) => {
          console.log(error)
        })
      },
      onSelectionChange(selectedRows) {
        this.selectedRows = selectedRows
      },
      excelDownloadTemplate() {
        if(this.selectedRows.length === 0) {
          swal({
            title: `템플릿 엑셀 다운로드`,
            text: `다운로드할 템플릿을 선택해주세요.`,
            confirmButtonText: '확인',
            type: 'warning'
          });
          return false;
        }
        // 템플릿 코드 목록 파싱
        let templateList = [];
        this.selectedRows.forEach(val => {
          templateList.push({
            kkoTemplateCd: val.kkoTemplateCd,
            channelId: val.channelId,
          });
        });
        this.$axios.post(`${USER_API_PREFIX}/template/excelDownload`, {
          templateList: templateList
        }, {responseType: 'blob'}).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.headers['content-disposition'].replace("attachment; filename=", ""));
          link.click();
          window.URL.revokeObjectURL(url);
        });
      }
    }
  };
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
