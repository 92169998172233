<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">JSON 조회</h6>
    <template>
      <div class="form-group row mb-0">
        <textarea class="form-control" v-model="kakaoTemplate" rows="15" placeholder="내용" :disabled="true"/>
      </div>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>

  </modal>
</template>

<script>
  import 'sweetalert2/dist/sweetalert2.css';
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';

  export default {
    name: 'TemplateResDataLayer',
    mixins: [ commJsUtilMixin],
    components: {
      [Select.name]       : Select,
      [Option.name]       : Option,
      [Table.name]        : Table,
      [TableColumn.name]  : TableColumn,
    },
    props: {
      kakaoTemplateRes: Object
    },
    data() {
      return {
        kakaoTemplate : null,
      }
    },
    created() {
      //null 체크 (화면 출력 X)
      for (let key in this.kakaoTemplateRes) {

        delete this.kakaoTemplateRes['block'];      //불필요 항목제거(템플릿 차단 여부)
        delete this.kakaoTemplateRes['dormant'];    //불필요 항목제거(템플릿 휴면 여부)

        if (this.kakaoTemplateRes[key] === null || this.kakaoTemplateRes[key] === 0 ) {
          delete this.kakaoTemplateRes[key];
        }

        // 버튼 , 바로연결 null 체크 (화면 출력 X)
        if(Array.isArray(this.kakaoTemplateRes[key])){
          if(this.kakaoTemplateRes[key].length == 0){
            delete this.kakaoTemplateRes[key];
          }else{
            let arrayParam = this.kakaoTemplateRes[key];
            for(let arraySize in arrayParam){
              let arrayObj = arrayParam[arraySize];
              for(let subKey in arrayObj){
                if (arrayObj[subKey] === null || arrayObj[subKey] === 0 || arrayObj[subKey] === '') {
                  delete arrayObj[subKey];
                }
              }
            }
          }
        }
      }
      if(this.kakaoTemplateRes.templateItem != null){
        let templateItemSummary = this.kakaoTemplateRes.templateItem.summary;
        if(templateItemSummary.title == null && templateItemSummary.description == null){
          delete this.kakaoTemplateRes.templateItem.summary;
        }
      }
      this.kakaoTemplate = JSON.stringify(this.kakaoTemplateRes);
    },
    methods: {
      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        this.$notify({
          message: message,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
  };
</script>

<style>
  .modal-content { width: 1000px !important }
  .current-row {
    background-color: #f5365c !important;
  }
</style>
