<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">SMS 템플릿 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card body-classes="pb-1" footer-classes="pb-2">
        <template slot="header">
          <h3 class="mb-0 d-inline">템플릿 목록</h3>
          <div class="float-right">
<!--            <base-button type="primary" @click="showBaseRegisterLayer=true">기본 템플릿 등록</base-button>-->
            <base-button type="primary" @click="register">템플릿 등록</base-button>
          </div>
        </template>
          <div class="form-row mb-1">
            <div class="col-md-1">
              <base-input>
                <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-9">
            </div>
            <div class="col-md-2">
              <div class="float-xl-right">
              </div>
            </div>
          </div>
          <div class="form-row mb-1">
            <div v-if="templateList && templateList.length > 0" class="card-columns bg-secondary p-2">
              <card class="text-center border-card-kakao" headerClasses="p-2" v-for="(option, index) in templateList">
                <template slot="header" style="height: 100px;">
                  <div class="form-row mb-1">
                    <div class="col-md-6 text-left">
                      <base-button type="primary" size="sm" class="no-click" outline>{{sendTypes[option.sendType]}}</base-button>
                    </div>
                    <div class="col-md-6">
                      <div class="float-xl-right">
                        <el-tooltip content="수정" placement="top">
                          <base-button type="primary" size="sm" class="btn-icon-only" @click="update(option, index)">
                            <i class="fas fa-edit"></i>
                          </base-button>
                        </el-tooltip>
                        <el-tooltip content="삭제" placement="top">
                          <base-button type="danger" size="sm" class="btn-icon-only" @click="remove(option, index)">
                            <i class="fas fa-trash"></i>
                          </base-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
                <div style="height: 300px;text-overflow:ellipsis;white-space:nowrap;word-wrap:normal;overflow-y:auto;overflow-x: hidden">
                  <h5 class="card-title text-left">{{option.templateCd}}</h5>
                  <p class="card-text text-left"style="white-space: pre-wrap;" v-if="option.templateContent.length <= 90">{{option.templateContent}}</p>
                  <p class="card-text text-left card-long"style="white-space: pre-wrap;" v-else>{{option.templateContent}}</p>
<!--                  <p class="card-text text-left"><small class="text-muted">{{option.useYn == 'Y' ? '사용' : '미사용'}}</small></p>-->
                </div>
              </card>
            </div>
            <div v-else class="el-table__empty-block" style="min-height: 350px; width: 100%;">
              <span class="el-table__empty-text">등록된 템플릿이 없습니다.</span>
            </div>
          </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">
              총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
            </p>
          </div>

          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @input="getList">
          </base-pagination>
        </div>
      </card>
    </div>
    <sms-template-register-layer v-if="showRegisterLayer" :modify="modify" :old-template="template" @register="registerTemplate" @close="showRegisterLayer=false"/>
    <base-template-register-layer v-if="showBaseRegisterLayer" @register="registerTemplate" @close="showBaseRegisterLayer=false"/>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';
  import swal from 'sweetalert2';
  import SmsTemplateRegisterLayer from './SmsTemplateRegisterLayer';
  import BaseTemplateRegisterLayer from './BaseTemplateRegisterLayer';

  export default {
    name: 'SmsTemplateList',
    mixins: [commJsUtilMixin, commPaginationMixin],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      SmsTemplateRegisterLayer,
      BaseTemplateRegisterLayer
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      }),
    },
    filters: {
    },
    data() {
      return {
        sendTypes: {"SM": "SMS", "LM": "LMS"},
        showLayer: false,
        showRegisterLayer: false,
        showBaseRegisterLayer: false,
        senderNo: undefined,
        templateList: [],
        template: {},
        modify : false
      };
    },
    created() {
      this.getList(this.pagination.currentPage);
    },
    methods: {
      /**
       * 목록 조회
       */
      getList(page) {
        const pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/smsTemplate/page`, {
          params: {
            page: pageNo,
            limit: this.pagination.perPage
          }
        }).then(res => {
          this.templateList = res.data.data; // 목록정보 세팅
          this.pagination.currentPage = pageNo; // 페이지 번호 세팅
          this.pagination.total = res.data.totalPage; // 전체페이지수 세팅
        });
      },
      /**
       * 템플릿 삭제 요청
       */
      remove(template, index){
        this._swalQuestion({ title:'삭제 하시겠습니까?' }, () => {
          this.$axios.delete(`${USER_API_PREFIX}/smsTemplate/remove`, {
            data : template
          }).then(res => {
            this.templateList.splice(index, 1)
          });
        });
      },
      /**
       * 템플릿 업데이트 레이어 호출
       */
      update(template, index){
        this.modify = true;
        this.template = template;
        this.showRegisterLayer = true;
      },

      /**
       * 템플릿 등록 레이어 호출
       */
      register(template, index){
        this.modify = false;
        this.template = {};
        this.showRegisterLayer = true;
      },

      /**
       * 템플릿 등록 콜백
       */
      registerTemplate(){
        this.getList(1);
        this.showRegisterLayer = false;
      }
    }
  };
</script>
<style lang="scss">
  @import '../../../../node_modules/bootstrap/scss/functions';
  @import "../../../../node_modules/bootstrap/scss/variables";
  @import "../../../../node_modules/bootstrap/scss/mixins";
  .card-columns {
    width : 100%;
    @include media-breakpoint-up(xs) {
      column-count: 1;
    }
    @include media-breakpoint-up(sm) {
      column-count: 2;
    }
    @include media-breakpoint-up(md) {
      column-count: 3;
    }
    @include media-breakpoint-up(lg) {
      column-count: 4;
    }
    @include media-breakpoint-up(xl) {
      column-count: 5;
      column-gap: 1.25rem;
    }
  }
  .card-columns .card{
    position: static;
    height: 400px;
    display: inline-block;
  }
  .card-columns .card .card-header{
    min-height: 50px;
  }
  .no-border-card .card-footer{
    border-top: 0;
  }
  .card .card-long{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .border-card-kakao{
    border: 4px #FFCD00;
  }
  .no-click {
    pointer-events: none;
  }
</style>
