<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title"></h6>
    <template>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label form-control-label">채널검색</label>
        <div class="col-md-7">
          <base-input v-model="searchKeyword"
                      name="searchKeyword"
                      placeholder="채널검색"
                      :maxlength="30">
          </base-input>
        </div>
        <div class="col-md-3">
          <base-button type="primary" @click="retrieveChannelList()">검색</base-button>
        </div>
      </div>

      <form role="form" class="needs-validation">
        <el-table :data="channelList"
                  :header-row-class-name="'thead-light'"
                  :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                  :cell-style="{'background-color' : '#FFFFFF'}"
                  header-cell-class-name="p-2"
                  cell-class-name="p-2"
                  :empty-text="'등록된 정보가 없습니다.'"
                  class="table-responsive table-flush"
                  style="max-height: 400px;overflow-y: auto">
          <el-table-column prop="channelId" label="채널ID" min-width="40%">
            <template v-slot="{row}">
              <a href="#" @click="selectSubchannel(row.channelId)"><u>{{ row.channelId }}</u></a>
            </template>
          </el-table-column>
          <el-table-column prop="channelName" label="채널명" min-width="60%"></el-table-column>
        </el-table>

      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>

  </modal>
</template>

<script>
  import 'sweetalert2/dist/sweetalert2.css';
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import {USER_API_PREFIX} from '@/shared/util/const';

  export default {
    name: 'SubChannelLayer',
    mixins: [ commJsUtilMixin],
    components: {
      [Select.name]       : Select,
      [Option.name]       : Option,
      [Table.name]        : Table,
      [TableColumn.name]  : TableColumn,
    },
    props: {
    },
    data() {
      return {
        channelId : null,
        channelList : [],
        searchKeyword : null
      }
    },
    created() {
      this.retrieveChannelList();
    },
    methods: {
      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 채널아이디 정보 조회
       */
      retrieveChannelList() {
        this.channelList = [];
        this.$axios.get(`${USER_API_PREFIX}/sender/channel-list`, {
          params: {
            searchKeyword : this.searchKeyword
          }
        }).then((res) => {
          if(res.data.result.code == "SUCCESS") {
            this.channelList = res.data.result.channelList;
          }
        }).catch((error) => {
          console.log(error)
        })
      },
      /**
       * 채널 선택 이벤트
       * @param selectedReceivers
       */
      selectSubchannel(channelId){
        this.$emit('register', channelId)
        this.closeLayer();
      },

      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        this.$notify({
          message: message,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
  };
</script>

<style>
  .modal-content { width: 1000px !important }
  .current-row {
    background-color: #f5365c !important;
  }
</style>
